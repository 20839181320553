import { CUSTOM_EMOJI_DETAIL_IMPORT, CUSTOM_EMOJIS_DETAIL_IMPORT } from '../actions/importer';
import { Map as ImmutableMap, fromJS } from 'immutable';

const initialState = ImmutableMap();

const normalizeCustomEmojiDetail = (state, customEmojiDetail) => {
  return state.set(customEmojiDetail.shortcode_with_domain, fromJS(customEmojiDetail));
};

const normalizeCustomEmojisDetail = (state, customEmojisDetail) => {
  customEmojisDetail.forEach(customEmojiDetail => {
    state = normalizeCustomEmojiDetail(state, customEmojiDetail);
  });

  return state;
};

export default function custom_emoji_details(state = initialState, action) {
  switch(action.type) {
  case CUSTOM_EMOJI_DETAIL_IMPORT:
    return normalizeCustomEmojiDetail(state, action.customEmojiDetail);
  case CUSTOM_EMOJIS_DETAIL_IMPORT:
    return normalizeCustomEmojisDetail(state, action.customEmojisDetail);
  default:
    return state;
  }
};
