// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { FormattedMessage } from 'react-intl';
import Emoji from './emoji';

const mapStateToProps = (state, { shortcode_with_domain }) => ({
  custom_emoji: state.getIn(['custom_emojis_detail', shortcode_with_domain]),
});

class CustomEmojiResult extends ImmutablePureComponent {
  static propTypes = {
    shortcode_with_domain: PropTypes.string.isRequired,
    custom_emoji: ImmutablePropTypes.map.isRequired,
  };

  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  setTargetRef = c => {
    this.target = c;
  };

  componentDidMount () {
    this.target?.addEventListener('mouseenter', this.handleMouseEnter, { capture: true });
    this.target?.addEventListener('mouseleave', this.handleMouseLeave, false);
  }

  componentWillUnmount () {
    this.target?.removeEventListener('mouseenter', this.handleMouseEnter, { capture: true });
    this.target?.removeEventListener('mouseleave', this.handleMouseLeave, false);
  }

  render () {
    const { shortcode_with_domain, custom_emoji } = this.props;

    return (
      <div className='custom-emoji__result'>
        <div className='custom-emoji__image' ref={this.setTargetRef}><Emoji emoji={shortcode_with_domain} hovered={this.state.hovered} url={custom_emoji.get('url')} static_url={custom_emoji.get('static_url')} /></div>
        <div className='custom-emoji__shortcode'>:{shortcode_with_domain}:</div>
        {custom_emoji.get('misskey_license') && 
          <div className='custom-emoji__misskey-license'>
            <div className='custom-emoji__misskey-license_header'><FormattedMessage id='search_results.custom_emojis.misskey_license' defaultMessage='License information' /></div>
            <div className='custom-emoji__misskey-license_content' dangerouslySetInnerHTML={{ __html: custom_emoji.get('misskey_license')}} />
          </div>
        }
      </div>
    );
  
  }

}

export default connect(mapStateToProps)(CustomEmojiResult);
